
import { ElMessage } from "element-plus";
import { defineComponent } from "vue";
// import { ElMessage } from "element-plus";
import helpers from "@/helpers/global";

export default defineComponent({
  name: "Pincode",
  created() {
    this.$store.dispatch("auth/initSignupFormDetails", null, { root: true });
    this.$store.dispatch("forceReady", null, { root: true });
  },
  data() {
    return {
      otp: {
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",
        six: "",
      },
      otpWarning: false,
    };
  },
  methods: {
    async continueFunc() {
      this.otpWarning = false;
      if (
        ("" + this.otp.one).length &&
        ("" + this.otp.two).length &&
        ("" + this.otp.three).length &&
        ("" + this.otp.four).length &&
        ("" + this.otp.five).length &&
        ("" + this.otp.six).length
      ) {
        const { email } = this.$store.getters["auth/signupFormDetails"] || {};
        const otp = this.otp;
        const pincode = `${otp.one}${otp.two}${otp.three}${otp.four}${otp.five}${otp.six}`;
        this.$store.commit("loadingStatus", true);
        await this.$store.dispatch(
          "auth/verifySignupOtp",
          { email: email, pincode: pincode },
          { root: true }
        );
        this.$store.commit("loadingStatus", false);
        if (this.$store.getters["auth/error"]) {
          // @todo error handler
          ElMessage.error("Invalid email or OTP");
          return;
        }

        const referralCode = helpers.getReferralCode();
        if (referralCode) {
          this.$router.push("/signup/info?" + referralCode);
        } else {
          this.$router.push({ name: "SignupInfo" });
        }
      } else {
        ElMessage.error("Invalid email or OTP");
        this.otpWarning = true;
      }
    },
    checkedOTPOne(e: any) {
      const value = e.target.value;
      if (value.length == 6) {
        this.otp.one = value.slice(0, 1);
        this.otp.two = value.slice(1, 2);
        this.otp.three = value.slice(2, 3);
        this.otp.four = value.slice(3, 4);
        this.otp.five = value.slice(4, 5);
        this.otp.six = value.slice(5, 6);
        const input = this.$refs["input_otp_six"] as any;
        input.select();
      } else if (value.length > 0) {
        this.otp.one = value.slice(0, 1);
        const input = this.$refs["input_otp_two"] as any;
        input.select();
      }
    },
    checkedOTPTwo() {
      const two = this.otp.two + "";
      if (!two.length) {
        this.otp.two = "";
        const input = this.$refs["input_otp_one"] as any;
        input.select();
      } else {
        const input = this.$refs["input_otp_three"] as any;
        input.select();
      }
    },
    checkedOTPThree() {
      const three = this.otp.three + "";
      if (!three.length) {
        this.otp.three = "";
        const input = this.$refs["input_otp_two"] as any;
        input.select();
      } else {
        const input = this.$refs["input_otp_four"] as any;
        input.select();
      }
    },
    checkedOTPFour() {
      const four = this.otp.four + "";
      if (!four.length) {
        this.otp.four = "";
        const input = this.$refs["input_otp_three"] as any;
        input.select();
      } else {
        const input = this.$refs["input_otp_five"] as any;
        input.select();
      }
    },
    checkedOTPFive() {
      const five = this.otp.five + "";
      if (!five.length) {
        this.otp.five = "";
        const input = this.$refs["input_otp_four"] as any;
        input.select();
      } else {
        const input = this.$refs["input_otp_six"] as any;
        input.select();
      }
    },
    checkedOTPSix() {
      const six = this.otp.six + "";
      if (!six.length) {
        this.otp.six = "";
        const input = this.$refs["input_otp_five"] as any;
        input.select();
      } else if (six.length > 1) {
        this.otp.six = six.slice(0, 1);
      }
    },
  },
  watch: {
    error(error) {
      if (error) {
        console.error(error);
      }
    },
  },
  computed: {
    // email() {
    //   return this.$store.getters["auth/email"];
    // },
    // error() {
    //   return this.$store.getters["auth/error"];
    // },
  },
});
